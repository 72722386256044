import React from 'react'
import styles from './PageTitle.module.scss'

export default function PageTitle({ preTitle, title }) {
  return (
    <h2 className={styles.titleWrapper}>
      {preTitle ? <span className={styles.preTitle}>{preTitle}</span> : null}
      <span className={styles.title}>{title}</span>
    </h2>
  )
}
