import React from 'react'
import ContactForm from '../components/ContactForm/ContactForm'
import PageTitle from '../components/PageTitle/PageTitle'
import SEO from '../components/SEO/SEO'

export default function ContactPage() {
  return (
    <>
      <SEO title="Contact" />
      <PageTitle title="Contact" />
      <ContactForm />
    </>
  )
}
